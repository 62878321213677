<template>
  <div class="col-md-8">
    <card type="chart">
      <template slot="header">
        <h5 class="card-category">{{ title }} - Action</h5>
        <h3 class="card-title mb-0">
          <div>
            <i class="tim-icons icon-bullet-list-67 text-primary "></i>
            Action
          </div>
        </h3>
      </template>
      <div class="chart-area">
        <ul class="commands-list">
          <li>
            <button class="btn btn-primary" v-on:click="putKill(title)">
              Add a kill
            </button>
          </li>
          <li>
            <button class="btn btn-primary" v-on:click="deleteKill(title)">
              Delete a kill
            </button>
          </li>
          <li>
            <button class="btn btn-primary" v-on:click="resetKill(title)">
              Reset the counter
            </button>
          </li>
        </ul>
      </div>
    </card>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    title: {
      type: String,
      default: {}
    }
  },
  methods: {
    async putKill(name) {
      const res = await fetch(
        "https://cdn.teyz.fr:7000/put/" + name.toLowerCase() + "/kill/"
      );
    },

    async deleteKill(name) {
      const res = await fetch(
        "https://cdn.teyz.fr:7000/update/" + name.toLowerCase() + "/kill/"
      );
    },

    async resetKill(name) {
      const res = await fetch(
        "https://cdn.teyz.fr:7000/delete/" + name.toLowerCase() + "/kill/"
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.showActionCounterSingleRoot {
  .commands-list {
    padding: 0;
    display: flex;
    list-style: none;
    justify-content: flex-start;

    li {
      padding: 0 16px;

      &:nth-child(1) {
        padding-left: 0;
      }
    }
  }
  @media screen and (max-width: 375px) {
    li {
      padding: 8px !important;
    }

    .commands-list {
      flex-wrap: wrap;
    }

    .btn {
      padding: 11px 30px !important;
    }
  }
}
</style>
