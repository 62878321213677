<template>
  <div class="col-md-4">
    <card type="chart">
      <template slot="header">
        <h5 class="card-category">Counter - {{ title }}</h5>
        <h3 class="card-title">
          <div>
            <i class="tim-icons icon-sound-wave text-primary "></i>
            Counter
          </div>
        </h3>
        <div class="counterText">
          <span>{{ counter }}</span>
        </div>
      </template>
    </card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      counter: {}
    };
  },
  beforeMount() {
    this.getCounter(this.title);
  },
  props: {
    title: {
      type: String,
      default: {}
    }
  },
  methods: {
    async getCounter(name) {
      const evtSource = new EventSource(
        "https://cdn.teyz.fr:7000/GET/" + name + "/kill",
        {
          crossDomain: true
        }
      );

      evtSource.addEventListener("connected", e => {
        let data = JSON.parse(e.data);
        this.counter = data.kill;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.counterText {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  span {
    font-size: 32px;
  }
}
</style>
