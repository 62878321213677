<template>
  <div class="ShowActionCounterRoot col-lg-12 p-0">
    <ShowActionCounterSingle title="Brawks" />
    <ShowActionCounterSingle title="Lowan" />
    <ShowActionCounterSingle title="Jbzz" />
  </div>
</template>

<script>
import ShowActionCounterSingle from "./ShowActionCounterSingle";
export default {
  components: { ShowActionCounterSingle },
  data() {
    return {};
  },

  beforeMount() {},

  methods: {}
};
</script>

<style lang="scss" scoped></style>
