<template>
  <div class="showActionCounterSingleRoot">
    <div class="row pr-3 pl-3">
      <ShowActionCounterCommands :title="title" />
      <ShowActionCounterCard :title="title" />
    </div>
  </div>
</template>

<script>
import ShowActionCounterCommands from "./ShowActionCounterCommands";
import ShowActionCounterCard from "./ShowActionCounterCard";
export default {
  components: { ShowActionCounterCommands, ShowActionCounterCard },
  data() {
    return {};
  },
  props: {
    title: {
      type: String,
      default: {}
    }
  }
};
</script>

<style lang="scss" scoped></style>
